@import '../../css/styles/colors';
@import '../../css/styles/variables';

.side-navi{
  overflow: auto;
  padding: 12px 0;
  background-color: #F3F5F9;
  height: 100%;
  position: relative;
  flex: 0 0 $navi-width;
  .level-one,.level-three,.level-two{
    line-height: 28px;
    a.name{
      &:hover{
        text-decoration: none;
        color: $secondary-color;
      }
    }
  }
  .level-one{
    > a.name{
      padding:0px 12px 0px;
    }
    font-size: 15px;
    .level-two{
      > a.name{
        padding:0 12px 0px 24px;
      }
      font-size: 14px;
      .level-three{
        > a.name{
          padding:0 12px 0px 36px;
        }
        font-size: 12px;
      }
    }
  }
}
@media screen and (max-width: $mobile-width) {
  .side-navi{
    padding-bottom: 160px;
    position: fixed;
    top: 48px;
    left: -$navi-width;
    z-index: 150;
    min-width: $navi-width;
    transition: left 0.3s;
    &.open{
      left: 0px;
    }
  }
  
}
