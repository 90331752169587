
$mobile-width: 779px;
$desktop-width: $mobile-width+1;

$default-component-padding: 20px;
$default-mobile-component-padding: 20px 0;
$container-max-width: 1078px;
$footer-max-width: 800px;

$navi-width: 220px;
$navi-mini-width: 60px;

$header-height: 60px;
$navi-height: 40px;
$header-z-index: 50;

$cubic-bezier-default: cubic-bezier(0.65, 0.22, 0.13, 0.93);
$cubic-bezier-default-reverse: cubic-bezier(0.93, 0.13, 0.22, 0.65);
$loading-cubic-bezier: cubic-bezier(0.75, 0.35, 0.34, 0.64);

$ios-present-cubic-bezier: cubic-bezier(0.95, 0.29, 0.59, 0.79);
