@import '../../css/styles/colors';
@import '../../css/styles/variables';

.top-navi{
  position: relative;
  height: 48px;
  z-index: 155;
  background-color: $primary-color;
  color: #FFF;
  // box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.65);
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,.1);
  .desktop-top-navi,.mobile-top-navi{
    height: 100%;
  }
  .menu{
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    width: 48px;
    svg .real-path{
      fill: #FFF;
    }
  }
  .desktop-top-navi{
    display: flex;
    .logo{
      width: auto;
    }
  }
  .blank{
    opacity: 0;
    height: 48px;
    width: 48px;
  }
  .logo{
    padding: 8px;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    text-align: center;
    a{
      width: 100%;
      display: flex;
      text-align: center;
      justify-content: center;
      &:hover{
        text-decoration: none;
      }
      .icon{
        overflow: hidden;
        background-color: #FFF;
        min-width: 32px;
        min-height: 32px;
        max-width: 32px;
        max-height: 32px;
        border-radius: 3px;
        svg{
          height: 100%;
          max-width: 100%;
          .real-path{
            fill: $secondary-color;
          }
        }
      }
      .text{
        line-height: 32px;
        padding: 0 8px;
        display: block;
        color: #FFF;
        font-size: 20px;
        font-weight: 700;
      }
    }
  }
  .mobile-top-navi{
    display: flex;
  }
}

@media screen and (max-width: $mobile-width) {
  .top-navi{ 
    position: fixed;
    width: 100%;
    .desktop-top-navi{
      display: none;
    }
  }
}

@media screen and (min-width: $desktop-width) {
  .top-navi .mobile-top-navi{
    display: none;
  }
}