@import '../../css/styles/colors';
@import '../../css/styles/variables';


.navi-gray-screen{
  opacity: 0;
}

@media screen and (max-width: $mobile-width) {
  .navi-gray-screen{
    &.open{
      top: 0;
      left: 0;
      position: fixed;
      display: block;
      background-color: $gray-screen-background-color;
      width: 100%;
      height: 100%;
      z-index: 125;
      opacity: 1;
      transition: opacity 0.3s;
    }
  }    
}