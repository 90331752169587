@import '../../../css/styles/colors';
@import '../../../css/styles/variables';

$header-height: 60px;
$button-margin: 8px;

$widthToggle: 780px;
$max-width: 1020px;

.home-comp{
  position: relative;
  display: flex;
  .text-block{
    flex: 1;
    max-height: 100%;
    background-color: #FFF;
    overflow: auto;
    .level-one,.level-two,.level-three{
      position: relative;
      z-index: 25;
      .block{
        margin: 12px 0;
        position: relative;
        display: flex;
        flex-flow: row;
        .text,.code{
          flex: 1;
          min-width: 50%;
        }
        .text{
          padding: 0 20px;
          h2{font-size: 20px;}
          h3{font-size: 16px;}
          h4{font-size: 14px;}
          .name{
          }   
          .url{
            padding: 4px;
            background-color: rgba(220,220,220,1);
            margin: 0 0 12px;
          }
          .desc{
            p,li{
              font-size: 14px;
            }
            font-size: 14px;
            ul{
              list-style: disc;
              padding-left: 24px;
            }
            table{
              border-spacing: 0;
              border-collapse: collapse;
              td,th{
                padding: 4px 12px;
                border: 1px solid rgba(190,190,190,1);
              }
            }
          }
        }
        .code{
          width: 10px;
          padding: 8px;
        }
      }
      
    }
  }
  .code-block{
    position: absolute;
    height: 100%;
    min-width: 50%;
    max-width: 50%;
    top: 0px;
    right: 0px;
    background-color: rgba(22,29,42,1);
  }
}
@media screen and (min-width: $mobile-width) {
  .home-comp{
    height: calc(100vh - 48px);
  }
}
@media screen and (max-width: $mobile-width) {
  .home-comp{
    margin-top: 48px;
    .text-block{
      .level-one,.level-two,.level-three{
        .block{
          flex-flow: column;
          .text{
            margin: 0 0 12px;
          }
          .text,.code{
            min-width: 100%;
          }
        }
      }
    }
    .code-block{
      display: none;
    }
  }
}


